import type { CookieConsentConfig } from 'vanilla-cookieconsent';

const pluginConfig: CookieConsentConfig = {
  guiOptions: {
    consentModal: {
      layout: 'box',
      position: 'bottom left',
      equalWeightButtons: true,
      flipButtons: false,
    },
    preferencesModal: {
      layout: 'box',
      position: 'left',
      equalWeightButtons: true,
      flipButtons: false,
    },
  },

  onFirstConsent: function () {
    console.log('onFirstAction fired');
  },

  onConsent: function ({ cookie }) {
    console.log('onConsent fired ...');
  },

  onChange: function ({ changedCategories, cookie }) {
    console.log('onChange fired ...');
  },

  categories: {
    necessary: {
      readOnly: true,
      enabled: true,
    },
    analytics: {
      autoClear: {
        cookies: [
          {
            name: /^(_ga|_gid|_gat|AMP_TOKEN|_gac_.*|__utma|__utmt|__utmb|__utmc|__utmz|__utmv)/,
          },
        ],
      },
    },
  },

  language: {
    default: 'pl',

    translations: {
      pl: {
        consentModal: {
          title: 'Pliki cookie na stronie Mobene',
          description:
            'Strona wykorzystuje pliki cookies. Dalsze przeglądanie witryny oznacza zgodę na zapis i wykorzystanie cookies niezbędnych. W zakresie pozostałych cookies możesz nimi zarządzać w ustawieniach.  <a href="#privacy-policy" data-cc="show-preferencesModal" class="cc__link">Zarządzanie Preferencjami</a>',
          acceptAllBtn: 'Zaakcpetuj Wszystkie',
          acceptNecessaryBtn: 'Odrzuć wszystko',
          showPreferencesBtn: 'Zarządzaj Preferencjami',
          //closeIconLabel: 'Close',
          footer: `
            <a href="./polityka-prywatnosci">Polityka Prywatności</a>
          `,
        },
        preferencesModal: {
          title: 'Cookie preferences',
          acceptAllBtn: 'Zaakceptuj wszystko',
          acceptNecessaryBtn: 'Orzuć wszystko',
          savePreferencesBtn: 'Zapisz preferencje',
          closeIconLabel: 'Zamknij',
          sections: [
            {
              title: 'Uzywanie plików cookie',
              description:
                'Administratorem danych osobowych pochodzących z plików cookie jest  Mobene sp. z o.o. Dane będą przetwarzane w celu umożliwienia prawidłowego działania strony, zaś w razie wyrażenia takiej zgody – w celach statystycznych, analitycznych oraz marketingowych. Więcej informacji o tym czym są pliki cookies, a także na temat przetwarzania Państwa danych osobowych pochodzących z plików cookie oraz przysługujących Państwu w związku z tym praw dostępnych jest w Polityce prywatności <a href="#" class="cc__link">Polityka Prywaności</a>',
            },
            {
              title:
                'Niezbędne pliki cookie pomagają w korzystaniu ze strony internetowej poprzez umożliwienie podstawowych funkcji, takich jak nawigacja po stronie i dostęp do bezpiecznych obszarów witryny. Strona internetowa nie może działać poprawnie bez tych plików cookie.',
              description: 'Opis',
              linkedCategory: 'necessary',
              cookieTable: {
                headers: {
                  name: 'Nazwa',
                  domain: 'Service',
                  description: 'Description',
                  expiration: 'Expiration',
                },
                body: [
                  {
                    name: '__Host-next-auth.csrf-token',
                    domain: 'mobene.pl',
                    description:
                      'Niezbędne do utrzymania sesji logowania  <a href="#das">Google Analytics</a>.',
                    expiration: 'Sesja',
                  },
                  {
                    name: '__Secure-next-auth.callback-url',
                    domain: 'mobene.pl',
                    description:
                      ' Niezbędne do utrzymania sesji logowania <a href="#das">Google Analytics</a>.',
                    expiration: 'Sesja',
                  },
                ],
              },
            },
            {
              title: 'Analityka Google',
              linkedCategory: 'necessary',
              cookieTable: {
                headers: {
                  name: 'Nazwa',
                  domain: 'Service',
                  description: 'Description',
                  expiration: 'Expiration',
                },
                body: [
                  {
                    name: '_ga',
                    domain: 'Google Analytics',
                    description:
                      'Cookie set by <a href="#das">Google Analytics</a>.',
                    expiration: 'Expires after 12 days',
                  },
                  {
                    name: '_gid',
                    domain: 'Google Analytics',
                    description:
                      'Cookie set by <a href="#das">Google Analytics</a>',
                    expiration: 'Session',
                  },
                ],
              },
            },
            {
              title: 'Więcej informacji:',
              description:
                ' Po więcej informacji skontaktuj się z nami: <a class="cc__link" href="#biuro@mobene.pl">Kontakt z nami</a>.',
            },
          ],
        },
      },
    },
  },
};

export default pluginConfig;
